import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { getCandles } from "../../Firebase/FirestoreDatabase";
import "./MapBox.css";
import { candleImage } from "../../constants/images";

class MapBox extends React.Component {
    _markers = [];

    constructor(props) {
        super(props);
        this.state = {
            lng: 4,
            lat: 50.5,
            zoom: 7,
            markers: [],
        };
        this.mapContainer = React.createRef();
    }

    componentDidMount() {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            center: [lng, lat],
            zoom: zoom,
            style: "mapbox://styles/weglow/clbe7xzo2000514t825nyh63a",
        });
        map.on("load", () => {
            this.setState({ map }, () => {
                this.updateMap().then(() => {
                    map.on("moveend", () => {
                        this.updateMarkers();
                    });
                });
            });
        });
    }

    updateMarkers() {
        let map = this.state.map;

        let features = map.querySourceFeatures("candles", {
            sourceLayer: "symbol",
        });

        let markers = this._markers;
        // console.log(markers);
        markers.forEach((marker) => {
            marker.remove();
        });
        // console.log(features);
        markers = [];
        for (var i = 0; i < features.length; i++) {
            var coords = features[i].geometry.coordinates;
            var props = features[i].properties;

            if (props.cluster) {
                // this property is only present when the feature is clustered
                // generate your clustered icon using props.point_count
                const el = document.createElement("div");
                el.className = "marker";
                el.style.backgroundImage = `url(${candleImage})`;
                el.style.width = `${32}px`;
                el.style.height = `${32}px`;
                el.style.backgroundSize = "100%";
                el.innerHTML = `<span class="marker_icon">${props.point_count}</span>`;

                let marker = new mapboxgl.Marker(el);

                marker.setLngLat(coords);
                marker.addTo(map);
                markers.push(marker);
            } else {
                // feature is not clustered, create an icon for it
                const el = document.createElement("div");
                el.className = "marker";
                el.style.backgroundImage = `url(${candleImage})`;
                el.style.width = `${32}px`;
                el.style.height = `${32}px`;
                el.style.backgroundSize = "100%";

                let marker = new mapboxgl.Marker(el);

                marker.setLngLat(coords);
                marker.addTo(map);
                markers.push(marker);
            }
            this._markers = markers;
        }
    }

    updateMap() {
        const map = this.state.map;
        /*const markers = this.state.markers;
                    if (markers) {
                        markers.forEach(marker => marker.remove());
                    }*/
        return getCandles().then((candles) => {
            //let newMarkers = [];

            map.addSource("candles", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: candles.map((candle) => {
                        return {
                            geometry: {
                                type: "Point",
                                coordinates: [candle.lng, candle.lat],
                            },
                            type: "Feature",
                            properties: {},
                        };
                    }),
                },
                cluster: true,
                clusterRadius: 80,
            });
            map.addLayer({
                id: "points",
                type: "symbol",
                source: "candles",
                layout: {
                    // "icon-image": "custom-marker",
                    // get the title name from the source's "title" property
                    "text-field": ["get", "title"],
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    "text-offset": [0, 1.25],
                    "text-anchor": "top",
                },
            });
            map.on("sourcedata", () => this.updateMarkers());

            /*candles.forEach((candle) => {
                  
                                  const el = document.createElement('div');
                                  el.className = 'marker';
                                  el.style.backgroundImage = `url(${candleImage})`;
                                  el.style.width = `${32}px`;
                                  el.style.height = `${32}px`;
                                  el.style.backgroundSize = '100%';
                  
                                  let marker = new mapboxgl.Marker(el);
                  
                                  marker.setLngLat([candle.lng, candle.lat]);
                                  marker.addTo(map);
                  
                  
                                  newMarkers.push(marker);
                  
                              });
                              this.setState({ markers: newMarkers });*/
            return;
        });
    }

    render() {
        return <div ref={this.mapContainer} className="mapContainer"></div>;
    }
}

export default MapBox;

/*<Map
    initialViewState={{
        longitude: -70.9,
        latitude: 42.35,
        zoom: 9
    }}

    style={{ height: 400 }}
    mapboxAccessToken='pk.eyJ1Ijoid2VnbG93IiwiYSI6ImNsYXdsMG1idDBleGUzcHA0bXV6czQyaWMifQ.2JmaCHMYFmNLPQrzNQkv9A'
    mapStyle={'mapbox://styles/mapbox/streets-v11'} />*/
