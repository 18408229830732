import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDS0OrvCcYFCMZwmQKSozLAyIwQXBZSDq0",
  authDomain: "we-glow.firebaseapp.com",
  databaseURL: "https://we-glow.firebaseio.com",
  projectId: "we-glow",
  storageBucket: "we-glow.appspot.com",
  messagingSenderId: "998810053265",
  appId: "1:998810053265:web:5eb95536012ebd15a9ad30",
  measurementId: "G-2DL5V4LC3Y"
};

const app = initializeApp(firebaseConfig);


const firestoreDb = getFirestore(app);


export { firestoreDb };