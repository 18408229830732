import React from "react";
import DonationAmountForm from "../DonationAmountForm/DonationAmountForm";
import "./DonationForm.css";
import DonationInfoForm from "../DonationInfoForm/DonationInfoForm";
import DonationResponseForm from "../DonationResponseForm/DonationResponseForm";
import { TranslationKeys, getLanguage, getTranslation } from "../../loc/mystrings.d";
import { KaarsKerk } from "../../constants/images";

class DonationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navigation: 0,
      amount: 5,
      displayName: '',
      email: '',
      message: '',
      coordinates: null
    };
  }

  componentDidMount() {
    // Success url example: http://localhost:3000/success?orderId=2116843980X42870&orderStatusId=100&paymentSessionId=2116843980
    if (window.location.pathname.includes('/success')) {
      const searchParams = new URLSearchParams(document.location.search);

      const orderId = searchParams.get('orderId'); // Get the order id from the url
      const responseCode = searchParams.get('orderStatusId'); // Get the responseCode from the url
      const paymentSessionId = searchParams.get('paymentSessionId'); // Get the paymentSessionId from the url

      switch (responseCode) {
        case '20':
        case '-90':
        case '-63':
          // Cancelled
          window.location.href = '/';
          break;
      }

      this.state.orderId = orderId;
      this.state.responseCode = responseCode;
      this.state.paymentSessionId = paymentSessionId;

      if (Number(responseCode) > 0) {
        this.setState({ navigation: 2 });
      }
    }
  }

  renderRoute() {
    switch (this.state.navigation) {
      case 0:
        // return (<DonationAmountForm amount={this.state.amount} onNextStep={(amount) => {
        //   this.setState({ navigation: 1, amount });
        // }} />);
        return (
          // Campaign is over, show message
          // <div className="form-container">
          <h3 className="text-center text-black">
            {getTranslation(TranslationKeys.CampaignOver)}
          </h3>
          // </div>
        )
      case 1:
        return (<DonationInfoForm onStepBack={() => this.setState({ navigation: 0 })} onNextStep={async ({ displayName, email, message, coordinates }) => {
          this.setState({ displayName, email, message, coordinates });

          // TODO: Local
          return fetch('https://europe-west1-we-glow.cloudfunctions.net/AddCandlePayNL', {
            // return fetch('http://127.0.0.1:5001/we-glow/europe-west1/AddCandlePayNL', {
            method: 'POST',
            body: JSON.stringify({
              lat: coordinates[1],
              lng: coordinates[0],
              flames: this.state.amount,
              name: displayName,
              email,
              project: "stopdarmkanker",
              description: message,
              language: getLanguage(),
            }),
            headers: {
              'Content-Type': 'application/json'
            },

          }).then(async (res) => {
            if (res.status === 200) {
              const responseBody = await res.json();
              window.location.href = responseBody.checkoutUrl;
            } else {
              // Server Error
              // res.json().then((json) => {
              //   console.error(json);
              // });
              return false;
            }
            return true;
          }).catch((error) => {
            // Error
            console.error(error);
            return false;
          })
        }} />);
      case 2:
        return (<DonationResponseForm responseCode={this.state.responseCode} orderId={this.state.orderId} paymentSessionId={this.state.paymentSessionId} onNextStep={() => {
          this.setState({ navigation: 0 });
        }} />);
      default:
        return (<DonationAmountForm amount={this.state.amount} onNextStep={(amount) => {
          this.setState({ navigation: 1, amount });
        }} />);
    }
  }

  render() {
    return (
      <div>
        <div className="form-container">
          <h1 className="text-center from-container">
            {getTranslation(TranslationKeys.Title2)}
          </h1>
        </div>
        <div className="row showOnMobile">
          <img src={KaarsKerk} alt="Afbeelding kaarsen in kerk"></img>
        </div>
        {this.renderRoute()}
      </div>
    );
  }
}

export default DonationForm;
