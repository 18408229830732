import React from "react";
import { InfoCard } from "../../components";
import { candleImage, tijd, amount, KaarsKerk } from "../../constants/images";
import DonationForm from "../DonationForm/DonationForm";
import "./Header.css";
import {
  getCandleAmount,
  getFlameAmount,
} from "../../Firebase/FirestoreDatabase";
import { LanguageOptions, TranslationKeys, getTranslation, setLanguage } from "../../loc/mystrings.d";

class Header extends React.Component {
  state = {
    candleCount: 0,
    flameCount: 0,
  };

  componentDidMount() {
    getCandleAmount().then((amount) => {
      this.setState({ candleCount: amount });
    });
    getFlameAmount().then((amount) => {
      this.setState({ flameCount: amount });
    });
  }

  getDate() {
    let futureDate = new Date('2023-09-05T22:00:00.000Z');
    let now = new Date();
    let difference = futureDate.getTime() - now.getTime();
    if (difference < 0) {
      return getTranslation(TranslationKeys.Finished);
    }
    if (difference > 86400000) {
      let dagen = Math.round(difference / 86400000);

      return `${dagen} ${dagen > 1 ? " dagen" : " dag"}`;
    }
    let uren = Math.round(difference / 3600000);

    return `${uren} ${uren > 1 ? " uren" : " uur"}`;
  }

  render() {
    return (
      <section>
        <div className="app__headercard container">
          <div className="row">
            <div className="col one">
              <div className="dropdown app__header-language-dropdown" style={{ marginBottom: 15 + 'px' }}>
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-globe" /> {getTranslation(TranslationKeys.LanguageName)}
                </button>
                <ul className="dropdown-menu">
                  {LanguageOptions().map((languageOption) => {
                    return (<li key={languageOption.value}><button onClick={() => setLanguage(languageOption.value)} className="dropdown-item">{languageOption.displayname}</button></li>);
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <DonationForm />
              <div className="row app__infocards">
                <div className="col col-xs-4 col-sm-4 col-lg-4 app__infocardContainer">
                  <InfoCard
                    image={amount}
                    total={`€ ${this.state.flameCount}`}
                    id="piggy_img"
                  />
                </div>
                <div className="col col-xs-4 col-sm-4 col-lg-4  app__infocardContainer">
                  <InfoCard
                    image={tijd}
                    total={this.getDate()}
                    id="clock_img"
                  />
                </div>
              </div>
            </div>
            <div className="col one">
              <div className="row dontShowOnMobile">
                <img src={KaarsKerk} alt="Afbeelding kaarsen in kerk"></img>
              </div>
              <div className="row app__infocards">
                <div className="col app__infocardContainer">
                  <div className="container">
                    <div className="app_candlecount_container_img">
                      <img src={candleImage} alt="icon" className="app_candlecount_img" />
                    </div>
                    <div className="app_candlecount_container_text">
                      <p className="app_candlecount_text">{this.state.candleCount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}

export default Header;
