import React from "react";

import { MapBox, Messagelist } from "../../components";

import "./ShowData.css";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";
const ShowData = () => (
  <section className="ShowData_section">
    <div className="container ShowData_container ">
      <div className="row">
        <div className="col left">
          <MapBox />
        </div>
        <div className="messageList_container">
          <div className="app__titleLeft">
            <h1 className="app__title">
            {getTranslation(TranslationKeys.Messages)}
            </h1>
          </div>
          <Messagelist />
        </div>
      </div>
    </div>
  </section>
);

export default ShowData;
