//Engelse vertalingen


export const enUS = {
    LanguageName: 'English', //AFBLIJVEN

    Title: 'Light your candle for Bart',
    Title2: 'Light your candle for Bart',
    Intro: 'Voetreis naar het einde van de wereld',
    Intro2: 'Met het vliegtuig doe je er nog geen drie uur over. Te voet duurt het vanuit de Lage Landen drie maanden eer je als "peregrino" Santiago de Compostela bereikt.',
    Intro3: 'En toch vertrek Bart eind mei voor een tocht van +/- 2.500 km. Het is absoluut geen bevlieging maar een droom die ik al heb van toen ik twintig was. Fons Oerlemans, de man die in 1974 op een vlot de Atlantische Oceaan overstak, zei me ooit op de hem geheel eigen wijze: "Als je een droom hebt, moet je stoppen met erover te lullen... "',
    Intro4: "En nu, op mijn 57, heb ik een goeie reden waarom ik het nu doe en niet later. Mijn goeie vriend Philippe overleed anderhalf jaar geleden aan darmkanker. Ook hij maakte me duidelijk dat je een droom nooit mag uitstellen.",

    CallToaction: 'Light your candle for Bart here',
    CallToaction2: '',
    CallToaction3: 'Buy your virtual flame here to support Team Special at the World Games in Berlin.',
    Messages: 'SUPPORT MESSAGES',
    // Thanks: 'THANKS TO',
    Share2: 'SHARE THE CAMPAIGN',
    Share1: 'SHARE',
    Share: 'Share',
    Next: 'Next',
    OwnDonation: 'Own donation',
    Information: 'Information',
    Name: 'Name',
    Email: 'Email',
    InvalidEmail: 'No valid email entered',
    Location: 'Where do you want to light your flame?',
    Message: 'Support Message',
    Required: 'Indicates required field',
    Accept: 'Please accept the',
    Requiredfield: 'Not all required fields have been filled in',
    Loading: 'Loading',
    Back: 'Back',
    Minamount: 'A minimum amount of €5 is required',
    Finished: 'Finished',
    AfterDonation: 'Your donation was well received. Your flame is currently being processed. Your flame and message will appear in a few minutes.',
    Thanks: 'Thanks for burning your flame!',
    Morehelp: 'Want to help even more? Share the campaign and let it be known that you have already lit a flame.',
    Donate: 'Donate',
    Loadingmessages: 'Show more messages',
    Moremessages: 'Messages are loading...',
    ServerError: 'An error has occurred. Please try again later.',
    CheckingPayment: "Thank you for your payment!",
    CheckingPaymentDescription: "We are now checking that we have received it correctly.",
    CheckingPaymentFailed: "Payment failed",
    CheckingPaymentDescriptionFailed: 'The payment failed. Contact us at the e-mail address: info@weglow.world.',
    LeesMeer: 'Read more',
    ToonMinder: 'Show less',
    ExtraInfoDescription: 'Bart lights a candle for us on the way to Santiago. Light a candle for him too and support Stop Bowel Cancer this way.',
    PaymentError1: 'The payment did not go through, please try again.',
    PaymentError2: 'If the problem persists, please contact us.',
    PaymentError3: 'If the payment was completed, please check the homepage and your candle should be there in a few minutes.',

    CampaignOver: 'The campaign is over. We want to thank everyone for burning your flames and the wonderful supporter messages!',
}