// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";

import React from 'react';

import { Navbar } from './components';
import { Header, Footer, Thanks, ShareSection, ShowData, ExtraInformation } from './container';
import './App.css';
import { initializeMultiLanguage } from "./loc/mystrings.d";

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = 'pk.eyJ1Ijoid2VnbG93IiwiYSI6ImNsYXdsMG1idDBleGUzcHA0bXV6czQyaWMifQ.2JmaCHMYFmNLPQrzNQkv9A';

class App extends React.Component {

  componentDidMount() {
    const locales = navigator.languages;
    initializeMultiLanguage(locales, this);
  }

  render() {
    return (
      <div>
        <Navbar />
        <Header />
        <ShareSection />
        <ShowData />
        <Thanks />
        {/* <ExtraInformation /> */}
        <Footer />
      </div>
    );
  }
}

export default App;