import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';

import "./assets/fonts/AvenirNextCondensed-Regular.ttf";
import "./assets/fonts/AvenirNextCondensed-DemiBold.ttf";

createRoot(document.getElementById('root')).render(<App />);