//Nederlandse vertalingen

export const nlBE = {
    LanguageName: 'Nederlands', //AFBLIJVEN

    Title: 'Brand jouw kaarsje voor Bart en steun hem met zijn reis naar Santiago de Compostella',
    Intro: 'Voetreis naar het einde van de wereld',
    Intro2: 'Met het vliegtuig doe je er nog geen drie uur over. Te voet duurt het vanuit de Lage Landen drie maanden eer je als "peregrino" Santiago de Compostela bereikt.',
    Intro3: 'En toch vertrek Bart eind mei voor een tocht van +/- 2.500 km. Het is absoluut geen bevlieging maar een droom die ik al heb van toen ik twintig was. Fons Oerlemans, de man die in 1974 op een vlot de Atlantische Oceaan overstak, zei me ooit op de hem geheel eigen wijze: "Als je een droom hebt, moet je stoppen met erover te lullen... "',
    Intro4: "En nu, op mijn 57, heb ik een goeie reden waarom ik het nu doe en niet later. Mijn goeie vriend Philippe overleed anderhalf jaar geleden aan darmkanker. Ook hij maakte me duidelijk dat je een droom nooit mag uitstellen.",

    Title2: 'Brand jouw bougie voor Bart ',
    CallToaction: 'Koop hier jouw virtuele kaars',
    Messages: 'Berichten',
    // Thanks: 'MET DANK AAN',
    Share1: 'DEEL DE CAMPAGNE',
    Share2: 'Deel',
    Next: 'Volgende',
    OwnDonation: 'Eigen donatie',
    Information: 'Gegevens',
    Name: 'Naam',
    Email: 'Email',
    Location: 'Waar wilt u uw kaars aansteken?',
    InvalidEmail: 'Geen geldige email ingevuld',
    Message: 'Support Message',
    Required: 'Geeft verplicht veld aan',
    Accept: 'Accepteer u de',
    Requiredfield: 'Niet alle verplichte velden zijn ingevuld',
    Loading: 'Laden',
    Back: 'Terug',
    Minamount: 'Een minimum bedrag van 5 euro is verplicht',
    Finished: 'Afgelopen',
    AfterDonation: 'Uw donatie werd goed ontvangen. Jouw kaars wordt momenteel verwerkt. Binnen enkele minuten zullen uw kaars en boodschap verschijnen.',
    Thanks: 'Bedankt voor het branden van je kaars!',
    Morehelp: 'Nog meer helpen? Deel de campagne en laat weten dat jij al een vlam aangestoken hebt.',
    Donate: 'Doneren',
    Loadingmessages: 'Meer berichten tonen',
    Moremessages: 'Berichten zijn aan het laden...',
    ServerError: 'Er is een fout opgetreden. Probeer het later opnieuw.',
    CheckingPayment: "Bedankt voor uw betaling!",
    CheckingPaymentDescription: "We controleren nu of we deze goed hebben ontvangen.",
    CheckingPaymentFailed: "Betaling mislukt",
    CheckingPaymentDescriptionFailed: "De betaling is niet goed gelukt. Contacteer ons op het e-mailadres:  info@weglow.world ",
    LeesMeer: 'Lees meer',
    ToonMinder: 'Toon minder',
    ExtraInfoDescription: 'Bart steekt op weg naar Santiago een kaarsje voor ons aan. Steek voor hem ook een kaarsje aan en steun zo Stop Darmkanker.',
    PaymentError1: 'De betaling is niet gelukt, probeer het opnieuw.',
    PaymentError2: 'Neem contact met ons op als het probleem aanhoudt.',
    PaymentError3: 'Als de betaling is voltooid, kijk dan op de homepage en je kaars zou daar binnen een paar minuten moeten staan.',

    CampaignOver: 'De campagne is afgelopen. We willen iedereen bedanken voor het branden van de kaarsjes en de prachtige berichten!',
}