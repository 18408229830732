import React from "react";

import "./PriceBox.css";

const PriceBox = ({ value, onClick, checked }) => {
  return (
    <div className={"custom-control custom-checkbox " +
      (checked ? "priceBoxActive" : "")}>
      <label
        className={
          "custom-control-label d-flex align-items-center justify-content-center " +
          (checked ? "priceBoxLabelActive" : "")
        }
        onClick={() => {
          onClick(value);
        }}
      >
        € {value}
      </label>
    </div>
  );
};

export default PriceBox;
