import React from "react";

import "./InfoCard.css";

const InfoCard = ({ image, total, id }) => {
  return (
    <div className="container">
      <div className="InfoCard_img">
        <img src={image} alt="icon" className="Infocard_Icon_img" id={id} />
      </div>
      <div className="InfoCard_Total">
        <p className="InfoCard_Total-p">{total}</p>
      </div>
    </div>
  );
};

export default InfoCard;
