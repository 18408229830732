import { firestoreDb } from './Initialize';
import { collection, getDocs } from 'firebase/firestore/lite';


let cache_candles = [];

let loadingCandles = false;
let returnPromises = [];

export const PROJECT = 'stopdarmkanker';

// CANDLE CLASS

/*
date
description
name
flames
lat
lng
*/

export const getFlameAmount = async () => {
    return getCandles().then((candles) => {
        return candles.map((candle => candle.flames)).reduce((a, b) => a + b, 0);
    });
}

export const getCandleAmount = async () => {
    return getCandles().then((candles) => {
        return candles.length;
    });
}

export const getCandles = (force) => {
    if (cache_candles.length !== 0 && !force) return Promise.resolve(cache_candles);
    if (loadingCandles) {
        let promise = new Promise((resolve, reject) => {
            returnPromises.push(resolve);
        });
        return promise;
    }
    const candleCollection = collection(firestoreDb, PROJECT);


    return getDocs(candleCollection).then((candleSnapshot) => {
        const candleCollections = candleSnapshot.docs.map(doc => doc.data().candles);
        let candles = [];
        candleCollections.forEach((collection) => candles = [...candles, ...collection]);
        candles = candles.sort((a, b) => {
            return b.date.toDate().getTime() - a.date.toDate().getTime();
        });
        cache_candles = candles;
        loadingCandles = false;
        returnPromises.forEach((resolve) => resolve());
        returnPromises = [];
        return candles;
    });

}

export const getRecentCandles = ({ count }) => {
    return getCandles().then((candles) => {
        return candles.slice(0, count);
    });
}