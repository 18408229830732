import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton
} from "react-share";
import "./ShareSection.css";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";

async function copyToClip() {
  await navigator.clipboard.writeText(window.location.href);
}

class ShareSection extends React.Component {
  render() {
    return (
      <section id="deel-de-campagne">
        <div className="container shareSection_container">
          <div className="row">
            <div className="col">
              <div className="app__titleLeft">
                <h1 className="app__title" >
                  <span>{getTranslation(TranslationKeys.Share1)}</span> {getTranslation(TranslationKeys.Share2)}
                </h1>
              </div>
              <div className="col">
                <div className="shareIcon_containers">
                  <FacebookShareButton
                    url={window.location.href}
                    quote={"Watch some vids!"}
                  >
                    <div className="btn btn_red_white sharebutton">
                      <i className="fa fa-facebook"></i> Facebook
                    </div>
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href} id="twitter" >
                    <div className="btn btn_red_white sharebutton">
                      <i className="fa fa-twitter"></i> Twitter
                    </div>
                  </TwitterShareButton>
                  <LinkedinShareButton url={window.location.href} id="linkedin">
                    <div className="btn btn_red_white sharebutton">
                      <i className="fa fa-linkedin"></i> Linkedin
                    </div>
                  </LinkedinShareButton>
                  <WhatsappShareButton url={window.location.href} id="whatsapp">
                    <div className="btn btn_red_white sharebutton">
                      <i className="fa fa-whatsapp"></i> Whatsapp
                    </div>
                  </WhatsappShareButton>
                  <button onClick={copyToClip} id="copy" style={{ border: 'none', backgroundColor: "transparent" }}>
                    <div className="btn btn_red_white sharebutton ">
                      <i className="fa fa-copy"></i> Link</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ShareSection;
