import React from "react";
import "./DonationAmountForm.css";
import PriceBox from "../../components/PriceBox/PriceBox";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";

class DonationAmountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: props.amount,
      otherPrice: "",
      otherPriceActive: false,
      showError: false,
    };
  }

  componentDidMount() { }

  render = () => {
    return (
      <section id="sectionForm">
        <div className="section-inner">
          <div className="app_donationForm container">
            <div className="row justify-content-center ">
              <form id="form_donate">
                <div className="card">
                  {/*Eerste formulier om verplicht een bedrag te selecteren. Verplicht voordat op VOLGENDE geklikt kan worden*/}
                  <div
                    id="donationCard_selectAmount"
                    className="frm-step active"
                    data-step="1"
                  >
                    <div className="card-header border-0 dontShowOnMobile">
                      <h4 className="text-center card-title font-lg">
                        {getTranslation(TranslationKeys.ExtraInfoDescription)}
                      </h4>
                    </div>

                    <div className="card-body text-center py-2">
                      <div className="row align-items-stretch">
                        <div className="col mb-3 px-1">
                          <PriceBox
                            value={5}
                            checked={this.state.price === 5}
                            onClick={() =>
                              this.setState({
                                price: 5,
                                otherPrice: "",
                                otherPriceActive: false,
                              })
                            }
                          />
                        </div>
                        <div className="col mb-3 px-1">
                          <PriceBox
                            value={10}
                            checked={this.state.price === 10}
                            onClick={() =>
                              this.setState({
                                price: 10,
                                otherPriceActive: false,
                                otherPrice: "",
                              })
                            }
                          />
                        </div>
                        <div className="col mb-3 px-1">
                          <PriceBox
                            value={25}
                            checked={this.state.price === 25}
                            onClick={() =>
                              this.setState({
                                price: 25,
                                otherPriceActive: false,
                                otherPrice: "",
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-1 px-1">
                          <input
                            name="other_amount"
                            value={this.state.otherPrice}
                            onChange={(e) => {
                              if (!e.target.value) {
                                this.setState({
                                  otherPriceActive: false,
                                  otherPrice: "",
                                  price: 5,
                                });
                                return;
                              }
                              if (parseInt(e.target.value)) {
                                if (parseInt(e.target.value) <= 10000) {
                                  this.setState({
                                    otherPriceActive: true,
                                    otherPrice: parseInt(e.target.value),
                                    price: parseInt(e.target.value),
                                  });
                                } else {
                                  this.setState({
                                    otherPriceActive: true,
                                    otherPrice: 10000,
                                    price: 10000,
                                  });
                                }
                              }
                            }}
                            type="text"
                            className={
                              "form-control custom-control " +
                              (this.state.otherPriceActive
                                ? "otherAmountActive"
                                : "")
                            }
                            maxLength="10"
                            placeholder={getTranslation(TranslationKeys.OwnDonation)}
                          />
                        </div>
                      </div>
                      <div
                        hidden={!this.state.showError}
                        className="alert alert-danger"
                        role="alert"
                        id="alert"
                      >
                        <p>{getTranslation(TranslationKeys.Minamount)}</p>
                      </div>

                      <div className="form-group py-1 mb-1">
                        <button
                          id="donateBtnStep1"
                          type="button"
                          className="btn btn-large btn_custom"
                          data-step="1"
                          onClick={() => {
                            if (this.state.price && this.state.price >= 5) {
                              this.props.onNextStep(this.state.price);
                            } else {
                              this.setState({ showError: true });
                            }
                          }}
                        >
                          {getTranslation(TranslationKeys.Next)}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                <div className="card">
                  <div
                    id="donationCard_selectAmount"
                    className="frm-step active"
                    data-step="1"
                  >
                    <div className="card-header border-0">
                      <h4 className="text-center card-title font-lg">
                        Bedankt iedereen!
                      </h4>
                    </div>

                    <div className="card-body text-center py-2">
                      <div className="row">
                        <div className="col mb-1 px-1">
                          De Warmste Week is afgelopen waardoor je niet meer kan doneren.
                          Een warme dankjewel aan alle donateurs!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default DonationAmountForm;

/*                      <div className="card-header border-0">
                        <h4 className="text-center text-uppercase card-title font-lg">
                          BRAND EEN <span className="card-title-inverted">KAARSJE</span>
                        </h4>
                      </div>*/
