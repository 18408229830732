import React from "react";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";
import mapboxgl from "mapbox-gl";
import MapBoxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "./DonationInfoForm.css";

class DonationInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: "",
      email: "",
      message: "",
      termAccepted: false,
      showError: false,
      errorMessage: "",
      coordinates: null,
      loading: false,
      showEmailError: false
    };
  }

  shareMessage = () => {
    if (navigator.canShare) {
      navigator.share({
        text: this.state.message
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(err => {
        console.log("Error while using Web share API:");
        console.log(err);
      });
    } else {
      console.log("Cannot share");
    }
  }

  componentDidMount() {
    const geocoder = new MapBoxGeocoder({
      accessToken: mapboxgl.accessToken,
      types:
        "country,region,place,postcode,locality,neighborhood,district,address,poi,poi.landmark",
    });
    geocoder.addTo("#geocoder");
    let icon = document.getElementsByClassName(
      "mapboxgl-ctrl-geocoder--icon-search"
    );
    icon[0].classList.add("hidden");
    let elements = document.getElementsByClassName(
      "mapboxgl-ctrl-geocoder--input"
    );
    elements[0].classList.add("form-control");
    elements[0].placeholder = "Adres zoeken";

    geocoder.on("result", (e) => {
      this.setState({ coordinates: e.result.geometry.coordinates });
    });

    geocoder.on("clear", () => {
      this.setState({ coordinates: null });
    });
  }

  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }



  render = () => {
    return (
      <section id="sectionForm">
        <div className="section-inner">
          <div className="app_donationForm container">
            <div className="row justify-content-center ">
              <form id="form_donate">
                <div className="card">
                  <div
                    id="donateStep2"
                    className="frm-step active"
                    data-step="2"
                  >
                    <div className="card-header border-0">
                      <h4 className="card-title text-center"> {getTranslation(TranslationKeys.Information)}</h4>
                    </div>
                    <div className="card-body text-left">
                      <div className="form-group">
                        <label className="form-label text-blue-dark m-0">
                          {getTranslation(TranslationKeys.Name)}<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control _req"
                          id="inputName"
                          placeholder={getTranslation(TranslationKeys.Name)}
                          maxLength="50"
                          value={this.state.displayName}
                          onChange={(e) => {
                            this.setState({ displayName: e.target.value });
                          }}
                        />
                        <small className="text-danger"></small>
                      </div>

                      <div className="form-group">
                        <label className="form-label text-blue-dark m-0">
                          {getTranslation(TranslationKeys.Email)}
                        </label>
                        <input
                          type="email"
                          className="form-control _req"
                          id="inputEmail"
                          placeholder={getTranslation(TranslationKeys.Email)}
                          /*required=""*/
                          value={this.state.email}

                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                        <small className="text-danger"></small>
                      </div>

                      <div className="form-group">
                        <label className="form-label text-blue-dark m-0">
                          {getTranslation(TranslationKeys.Location)}<sup>*</sup>
                        </label>
                        <div id="geocoder" className="geocoder_container" />
                        <small className="text-danger"></small>
                      </div>

                      <div className="form-group">
                        <label className="form-label text-blue-dark m-0">
                          {getTranslation(TranslationKeys.Message)}
                        </label>
                        <textarea
                          className="form-control dark-border"
                          maxLength="140"
                          id="inputMessage"
                          rows="3"
                          placeholder={getTranslation(TranslationKeys.Message)}
                          value={this.state.message}
                          onChange={(e) => {
                            this.setState({ message: e.target.value });
                          }}
                        ></textarea>
                      </div>
                      <p className="text-muted">
                        <small>
                          <em id="privacy">
                            <sup>*</sup>{getTranslation(TranslationKeys.Required)}
                          </em>
                        </small>
                      </p>
                      <div className="form-group">
                        <div className="form-check-container">
                          <input
                            id="terms-checkbox"
                            className="form-check-input-custom"
                            type="checkbox"
                            onChange={(e) => {
                              this.setState({
                                termAccepted: e.target.checked,
                              });
                            }}
                            checked={this.state.termAccepted}
                          />
                          <label className="form-check-label font-weight-bold" htmlFor="terms-checkbox">
                            {getTranslation(TranslationKeys.Accept)} <a className="inline_link" target={'_blank'} href="https://weglow.world/onewebmedia/Privacystatement.pdf">Terms of conditions</a> {" & "}
                            <a className="inline_link" target={'_blank'} href="https://weglow.world/onewebmedia/Privacystatement.pdf">Privacy policy</a>
                          </label>
                        </div>
                      </div>
                      <div
                        hidden={!this.state.showError}
                        className="alert"
                        role="alert"
                        id="alert"
                      >
                        <p className="errorMessage">{this.state.errorMessage}</p>
                      </div>
                      <div className="form-group">
                        <div className="form-group py-3">
                          <div className="d-flex justify-content-around flex-wrap">
                            <div className="mx-1 mb-2">
                              <button
                                disabled={this.state.loading}
                                id="btn_back"
                                type="button"
                                className="btn btn-large btn-outline-orange frm-step-back-btn"
                                data-step="2"
                                onClick={() => this.props.onStepBack()}
                              >
                                {getTranslation(TranslationKeys.Back)}
                              </button>
                            </div>
                            <div className="mx-1 mb-2">
                              <button
                                disabled={this.state.loading}
                                id="donateBtnStep2"
                                type="button"
                                className="btn btn-large btn-orange frm-step-btn"
                                data-step="2"
                                onClick={() => {
                                  this.setState({ showError: false });
                                  let {
                                    displayName,
                                    email,
                                    termAccepted,
                                    message,
                                    coordinates,
                                  } = this.state;

                                  if (email) {
                                    if (!this.isValidEmail(email)) {
                                      this.setState({ showError: true, errorMessage: getTranslation(TranslationKeys.InvalidEmail) })
                                      return;
                                    }
                                  }

                                  if (
                                    displayName &&
                                    termAccepted &&
                                    coordinates
                                  ) {
                                    this.setState({ loading: true });
                                    let serverResonse = this.props.onNextStep({
                                      displayName,
                                      message,
                                      coordinates,
                                      email
                                    });
                                    serverResonse.then((result) => {
                                      if (!result) { //Server error
                                        this.setState({ showError: true, loading: false, errorMessage: getTranslation(TranslationKeys.ServerError) });
                                      }
                                    });
                                  } else {
                                    this.setState({ showError: true, errorMessage: getTranslation(TranslationKeys.Requiredfield) });
                                  }
                                }}
                              >
                                {this.state.loading ? getTranslation(TranslationKeys.Loading) : getTranslation(TranslationKeys.Next)}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default DonationInfoForm;
