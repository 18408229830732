import React from "react";

import "./MessageItem.css";

const MessageItem = ({ name, message, amount, date }) => {
  return (
    <div className="d-flex justify-content-between align-items-start">
      <div className="ms-2 me-auto">
        <div className="fw-bold">{name}</div>
        {message}
      </div>
      <div>
        <div>{date}</div>
        <div>€{amount}</div>
      </div>
    </div>
  );
};

export default MessageItem;
