import { nlBE } from "./nl-BE";
import { frBE } from "./fr-BE";
import { enUS } from "./en-US";
import { Cookies } from 'react-cookie';

export let language = 'nl';
let rootComponent;
const cookies = new Cookies();

export const initializeMultiLanguage = (locales, app) => {
    rootComponent = app;

    let cookieLanguage = cookies.get('weglow_language') || null;
    if (cookieLanguage) {
        language = cookieLanguage;
        updateLanguage();
        return;
    }
    for (let i = 0; i < locales.length; i++) {
        if (locales[i].indexOf('nl') !== -1) {
            language = 'nl';
            updateLanguage();
            return;
        }
        /*if (locales[i].indexOf('fr') !== -1) {
            language = 'fr';
            updateLanguage();
            return;
        }*/
        if (locales[i].indexOf('en') !== -1) {
            language = 'en';
            updateLanguage();
            return;
        }
    }

}

export const setLanguage = (value) => {
    language = value;
    console.log('setlanguage')
    cookies.set('weglow_language', value);
    updateLanguage();

}

export const LanguageOptions = () => {
    return [
        {
            displayname: nlBE["LanguageName"],
            value: 'nl'
        },
        /*{
            displayname: frBE["LanguageName"],
            value: 'fr'
        }*/,
        {
            displayname: enUS["LanguageName"],
            value: 'en'
        }
    ]
}

export const getTranslation = (key) => {
    switch (language) {
        case 'nl':
            return nlBE[key];
        case 'fr':
            return frBE[key];
        case 'en':
            return enUS[key];
        default:
            return nlBE[key];
    }
}

export const getLanguage = () => {
    return language.toUpperCase();
}

const updateLanguage = () => {
    if (rootComponent) {
        rootComponent.forceUpdate();
    }
}



// Keys 
export const TranslationKeys = {
    LanguageName: "LanguageName",//AFBLIJVEN


    Title: 'Title',
    Intro: 'Intro',
    Intro2: 'Intro2',
    Intro3: 'Intro3',
    Intro4: 'Intro4',
    Title2: 'Title2',
    CallToaction: 'CallToaction',
    Messages: 'Messages',
    Share: 'Share',
    Share2: 'Share2',
    Next: 'Next',
    OwnDonation: 'OwnDonation',
    Information: 'Information',
    Name: 'Name',
    Email: 'Email',
    Location: 'Location',
    Message: 'Message',
    Required: 'Required',
    Accept: 'Accept',
    Requiredfield: 'Requiredfield',
    ErrorMessage: 'ErrorMessage',
    InvalidEmail: 'InvalidEmail',
    Loading: 'Loading',
    Back: "Back",
    Minamount: 'Minamount',
    Finished: 'Finished',
    AfterDonation: 'AfterDonation',
    Thanks: 'Thanks',
    CheckingPayment: 'CheckingPayment',
    CheckingPaymentDescription: 'CheckingPaymentDescription',
    CheckingPaymentFailed: 'CheckingPaymentFailed',
    CheckingPaymentDescriptionFailed: 'CheckingPaymentFailedDescription',
    Morehelp: 'Morehelp',
    Donate: 'Donate',
    Loadingmessages: 'Loadingmessages',
    Moremessages: 'Moremessages',
    ServerError: 'ServerError',
    LeesMeer: 'LeesMeer',
    ToonMinder: 'ToonMinder',
    ExtraInfoDescription: 'ExtraInfoDescription',
    PaymentError1: 'PaymentError1',
    PaymentError2: 'PaymentError2',
    PaymentError3: 'PaymentError3',
    CampaignOver: 'CampaignOver',
}