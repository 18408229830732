import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { logoBlack } from "../../constants/images";

import "./Footer.css";

const Footer = () => (
  <div className="app__footer">
    <div className="container .app__footer-links ">
      <div className="row d-flex justify-content-center text-center">
        <div className="weglowlogo">
          <a href="https://weglow.world/" rel="noopener noreferrer" target="_blank">
            <img src={logoBlack} alt="weglow logo" className="footer_logo" />
          </a>
        </div>
        <p>Een campagne gemaakt door <a href="https://weglow.world/" rel="noopener noreferrer" target="_blank">WeGlow.World</a></p>
      </div>
      <div className="row center-block text-center">
        <div className="col">
          <p>&copy; Copyright 2023</p>
        </div>
        <div className="col">
          <p><a className="inline_link" target={'_blank'} href="https://weglow.world/onewebmedia/Privacystatement.pdf">Terms of conditions</a> & <a className="inline_link" target={'_blank'} href="https://weglow.world/onewebmedia/Privacystatement.pdf">Privacy policy</a></p>
        </div>
        <div className="col">
          <p>All Rights Reserved</p>
        </div>
      </div>
      <div className="row center-block text-center">
        <div className="app__footer-links_icons ">
          <a href="https://www.facebook.com/weglow.world" target="_blank" rel="noopener noreferrer">
            <FiFacebook />
          </a>
          <a href="https://twitter.com/weglowworld" target="_blank" rel="noopener noreferrer">
            <FiTwitter />
          </a>
          <a href="https://www.instagram.com/weglow_world/" target="_blank" rel="noopener noreferrer">
            <FiInstagram />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
