import React from "react";

import { logo, VZWLogo } from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="app_navbar-root">
      <div className="app__navbar-logo">
        <a href="https://weglow.world/" >
          <img src={logo} alt="WeGlow logo" />
        </a>
      </div>
      <nav className="app__navbar container">
        <div className="d-flex justify-content-end app__navbar-logo-SOB">
          <a href="https://www.stopdarmkanker.be/">
            <img src={VZWLogo} alt="Stop darmkanker logo" />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
