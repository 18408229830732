//Franse vertalingen


export const frBE = {
    LanguageName: 'Français', //AFBLIJVEN

    TEST: 'l\' appostrof',// Voorbeeld 
    TEST3: `l' `, // Voorbeeld

    Title: 'Title',
    Intro: "Tekst",
    Title3: 'Allumez la flamme avec Team Special pour les Jeux Mondiaux.',
    CallToaction2: '',
    CallToaction3: 'Achetez votre flamme virtuelle ici pour soutenir Team Special aux Jeux Mondiaux de Berlin.',
    Messages: 'SUPPORT MESSAGES',
    // Thanks: 'AVEC REMERCIEMENTS',
    Share2: 'LA CAMPAGNE',
    Share1: 'PARTAGER',
    Share: 'Partager',
    Next: 'Suivant',
    OwnDonation: 'Donation individuelle',
    Information: 'Information',
    Name: 'Nom',
    Email: 'Email',
    InvalidEmail: "Aucune adresse e-mail valide n'a été saisie",
    Location: 'Où voulez-vous allumer votre flamme ?',
    Message: 'Support Message',
    Required: 'Indique un domaine obligatoire',
    Accept: 'Veuillez accepter le',
    Requiredfield: 'Tous les domaines obligatoires ne sont pas remplis',
    Loading: 'Loading',
    Back: 'Retour',
    Minamount: 'Un montant minimum de 5 € est requis',
    Finished: 'Terminé',
    AfterDonation: 'Votre don a été bien reçu. Votre flamme est en cours de traitement. Votre flamme et votre message apparaîtront dans quelques minutes.',
    Thanks: "Merci d’avoir allumé votre flamme!",
    Morehelp: "Vous voulez aider encore plus? Partagez la campagne et faites savoir que vous avez déjà allumé une flamme.",
    Donate: 'Donnez',
    Loadingmessages: 'Plus de messages',
    Moremessages: 'Les messages sont en chargement...',
    ServerError: "Une erreur s'est produite. Veuillez réessayer plus tard.",
    CheckingPayment: "Merci pour votre paiement !",
    CheckingPaymentDescription: "Nous sommes en train de vérifier si nous l'avons reçu.",
    CheckingPaymentFailed: "Paiement échoué",
    CheckingPaymentDescriptionFailed: "Le paiement a échoué. Veuillez nous contacter à l'adresse électronique suivante : info@weglow.world",
    LeesMeer: 'Lire plus',
    ToonMinder: 'Montrer moins',
    ExtraInfoDescription: 'Bart allume une bougie pour nous sur le chemin de Santiago. Allumez une bougie pour lui aussi et soutenez ainsi Stop Bowel Cancer.',
    PaymentError1: 'Le paiement n\'a pas été effectué, veuillez réessayer.',
    PaymentError2: 'Si le problème persiste, veuillez nous contacter.',
    PaymentError3: 'Si le paiement a été effectué, veuillez consulter la page d\'accueil et votre bougie devrait s\'y trouver dans quelques minutes.',

    CampaignOver: 'La campagne est terminée. Nous voulons remercier tout le monde pour avoir allumé vos flammes et les merveilleux messages de soutien !',
}