import React from "react";
import "./DonationResponseForm.css";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";

class DonationResponseForm extends React.Component {
  constructor(props) {
    super(props);

    var navigation = 0;
    // 100 is betaald
    // 50 in betaalomgeving
    // 95 geautoriseerd
    // 85 verifieren

    switch (props.responseCode) {
      case '100':
        navigation = 0;
        break;
      case '50':
      case '95':
      case '85':
        navigation = 2;
        break;
      default:
        navigation = 1;
        break;
    }

    this.state = {
      orderId: props.orderId,
      responseCode: props.responseCode,
      navigation: navigation
    };
  }

  componentDidMount() {

  }

  renderRoute() {
    switch (this.state.navigation) {
      case 0:
        return (
          <div className="card">
            <div className="card-header border-0">
              <h4 className="text-center card-title font-lg">
                {getTranslation(TranslationKeys.Thanks)}
              </h4>
            </div>
            <div className="card-body text-center py-2">
              <div className="row align-items-stretch">
                <div className="col mb-3 px-1">
                  <p>
                    {getTranslation(TranslationKeys.AfterDonation)}
                  </p>
                  <p>
                    {getTranslation(TranslationKeys.Morehelp)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="card">
            <div className="card-header border-0">
              <h4 className="text-center card-title font-lg">
                Something went wrong
              </h4>
            </div>
            <div className="card-body text-center py-2">
              <div className="row align-items-stretch">
                <div className="col mb-3 px-1">
                  <p>
                    {getTranslation(TranslationKeys.PaymentError1)}
                  </p>
                  <p>
                    {getTranslation(TranslationKeys.PaymentError2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="card">
            <div className="card-header border-0">
              <h4 className="text-center card-title font-lg">
                Payment is still pending
              </h4>
            </div>
            <div className="card-body text-center py-2">
              <div className="row align-items-stretch">
                <div className="col mb-3 px-1">
                  <p>
                    {getTranslation(TranslationKeys.AfterDonation)}
                  </p>
                  <p>
                    {getTranslation(TranslationKeys.PaymentError3)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }

  render() {
    return (
      <section id="sectionForm">
        <div className="section-inner">
          <div className="app_donationForm container">
            <div className="row justify-content-center">
              {this.renderRoute()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DonationResponseForm;