import React from "react";

// import {
//   Lotto, PYQ, Telenet
// } from "../../constants/images";

import "./Thanks.css";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";

const Thanks = () => (
  <section className="MDA_section">
    <div className="container">
      <div className="row">
        <div
          className="col app__titleLeft"
          style={{ "marginBottom": "1.5rem" }}
        >
          <h1 className="app__title">
            {getTranslation(TranslationKeys.Thanks)}
          </h1>
        </div>
      </div>
    </div>

  </section>
);

export default Thanks;
