import React from "react";
import MessageItem from "../../components/MessageItem/MessageItem";
import { getRecentCandles } from "../../Firebase/FirestoreDatabase";
import { TranslationKeys, getTranslation } from "../../loc/mystrings.d";
import "./Messagelist.css";

class Messagelist extends React.Component {
  state = {
    count: 10,
    loadingMessages: false,
    messagesAvailable: true,
    candles: [],
  };

  componentDidMount() {
    getRecentCandles({ count: 11 }).then((candles) => {

      this.setState({ candles, messagesAvailable: candles.length >= 11 });
    });
  }

  formatDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  };

  renderMessageItems = () => {
    let candles = this.state.candles.slice(0, this.state.count);
    return candles.map((candle) => {
      return (
        <li key={candle.number} className="list-group-item">
          <MessageItem
            name={candle.name}
            message={candle.description}
            amount={candle.flames}
            date={this.formatDate(candle.date.toDate())}
          />
        </li>
      );
    });
  };

  render() {
    return (
      <div className="amateur">
        <ul className="list-group">{this.renderMessageItems()}</ul>
        <div
          hidden={!this.state.messagesAvailable}
          onClick={() => {
            this.setState({ loadingMessages: true });
            let count = this.state.count + 10;
            getRecentCandles({ count: count + 1 }).then((candles) => {
              this.setState({
                count,
                messagesAvailable: candles.length >= count + 1,
                candles,
                loadingMessages: false,
              });
            });
          }}
          className="LoadMessages_container"
        >
          <span className="LoadMessages">
            {this.state.loadingMessages
              ? getTranslation(TranslationKeys.Moremessages)
              : getTranslation(TranslationKeys.Loadingmessages)}
          </span>
        </div>
      </div>
    );
  }
}

export default Messagelist;
